import React, { useCallback, useState } from "react";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import FaltasTabla from "../components/incidencias/TablaFaltas";
import IncidentForm from "../components/incidencias/incidenciasFecha";
import GuardiasFindeSemana from "../components/incidencias/GuardiasFindeSemana";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";

const IncidenciasPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
    console.log("Selected IDs:", ids); // Maneja los IDs seleccionados aquí
  }, []);

  return (
    <div>
      <Navbar />
      <div className="container">
        <div className="row">
          <SelectIdsComponent onSelect={handleIdsSelection} isMulti={false} />
        </div>

        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias por dia
        </StyledSubtitle>
        <div>
          <FaltasTabla />
        </div>
        <StyledSubtitle color={colors.theme} size={24}>
          Crear Incidencia por fecha
        </StyledSubtitle>
        <div>
          <IncidentForm />
        </div>
        <StyledSubtitle color={colors.theme} size={24}>
          Carga de Incidencias en Excel
        </StyledSubtitle>
        <div>
          <GuardiasFindeSemana />
        </div>
      </div>
    </div>
  );
};

export default IncidenciasPage;
