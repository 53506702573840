import React, { useEffect, useState, useRef, useCallback } from "react";
import { TabulatorFull as Tabulator } from "tabulator-tables";
import "tabulator-tables/dist/css/tabulator.min.css";
import "materialize-css/dist/css/materialize.min.css";
import { hasWritePermissions } from "../apiCredentials";
import "materialize-css/dist/js/materialize.min.js";
import M from "materialize-css/dist/js/materialize.min.js";
import * as XLSX from "xlsx"; // Import xlsx library
import { DateTime } from "luxon";
import { StyledExcelButton } from "../Styles";

window.DateTime = DateTime;

const EmployeeCatalogComponent = ({ selectedIds }) => {
  // Input donde el usuario ingresa el EmpID a cambiar
  const [employeeIdToChange, setEmployeeIdToChange] = useState("");
  // Determina si el usuario tiene permisos de edición
  const canWrite = hasWritePermissions();

  // Contratos seleccionados
  const idsParam = selectedIds.join(",");
  console.log("idsParam:", idsParam);

  // Obtener CLIENTID
  const CLIENTID = idsParam;

  // Estados
  const [loading, setLoading] = useState(true);
  const [table, setTable] = useState(null);

  // Referencia al contenedor de la tabla
  const tableRef = useRef(null);

  // Endpoints
  const URL_GET = `https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleados/${CLIENTID}`;
  const URL_POST =
    "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/empleado/actualizar";

  // --------------------------------------------------------------------------
  // 1) FUNCIÓN PARA CARGAR LA TABLA
  // --------------------------------------------------------------------------
  const loadTable = useCallback(async () => {
    try {
      const response = await fetch(URL_GET);
      if (!response.ok) {
        throw new Error("Failed to fetch data");
      }

      if (idsParam) {
        const jsdata = await response.json();

        if (tableRef.current) {
          const newTable = new Tabulator(tableRef.current, {
            data: jsdata,
            maxWidth: "100%",
            layout: "fitData",
            renderHorizontal: "virtual",
            resizableColumnFit: true,
            pagination: "local", // local pagination
            paginationSize: 25, // rows per page
            columns: [
              {
                title: "#",
                field: "rownum",
                formatter: "rownum",
                width: 30,
                frozen: true,
              },
              {
                title: "Emp ID",
                field: "empnum",
                frozen: true,
                headerFilter: "input",
                headerFilterParams: {
                  placeholder: "Search...",
                  minSearchChars: 2,
                },
                headerFilterPlaceholder: "Buscar...",
              },
              {
                title: "Nombre",
                field: "nombre",
                editor: "input",
                validator: "required",
                headerFilter: "input",
                headerFilterParams: {
                  minSearchChars: 2,
                },
                headerFilterFunc: (headerValue, rowValue) =>
                  rowValue.toLowerCase().includes(headerValue.toLowerCase()),
              },
              {
                title: "Paterno",
                field: "paterno",
                editor: "input",
                validator: "required",
                headerFilter: "input",
                headerFilterParams: {
                  minSearchChars: 2,
                },
                headerFilterFunc: (headerValue, rowValue) =>
                  rowValue.toLowerCase().includes(headerValue.toLowerCase()),
              },
              {
                title: "Materno",
                field: "materno",
                editor: "input",
                headerFilter: "input",
                headerFilterParams: {
                  minSearchChars: 2,
                },
                headerFilterFunc: (headerValue, rowValue) =>
                  rowValue.toLowerCase().includes(headerValue.toLowerCase()),
              },
              {
                title: "CURP",
                field: "curp",
                editor: "input",
                validator: ["minLength:18", "maxLength:18", "string"],
                formatter: "plaintext",
                headerFilter: "input",
                headerFilterParams: {
                  minSearchChars: 2,
                },
                headerFilterFunc: (headerValue, rowValue) =>
                  rowValue.toLowerCase().includes(headerValue.toLowerCase()),
              },
              {
                title: "Contrato",
                field: "contract_code",
                editor: "input",
                validator: "required",
              },
              {
                title: "Puesto",
                field: "e_attribute3",
                editor: "input",
              },
              {
                title: "Ingreso",
                field: "fechadeinicio",
                editor: "date",
                validator: "required",
                editorParams: {
                  format: "yyyy-MM-dd",
                  verticalNavigation: "table",
                },
              },
              {
                title: "Entrada",
                field: "horaentrada",
                validator: "required",
                editor: "time",
                editorParams: { format: "HH:mm" },
                formatter: (cell) =>
                  DateTime.fromFormat(cell.getValue(), "HH:mm").toFormat(
                    "HH:mm"
                  ),
              },
              {
                title: "Salida",
                field: "horasalida",
                validator: "required",
                editor: "time",
                editorParams: { format: "HH:mm" },
                formatter: (cell) =>
                  DateTime.fromFormat(cell.getValue(), "HH:mm").toFormat(
                    "HH:mm"
                  ),
              },
              {
                title: "Site",
                field: "e_attribute4",
                editor: "list",
                editorParams: {
                  values: {
                    XOLA: "XOLA",
                    AÑIL: "AÑIL",
                    TRONCOSO: "TRONCOSO",
                    "HOME OFFICE": "HOME OFFICE",
                  },
                },
                headerFilter: true,
                headerFilterParams: {
                  values: {
                    XOLA: "XOLA",
                    AÑIL: "AÑIL",
                    TRONCOSO: "TRONCOSO",
                    "HOME OFFICE": "HOME OFFICE",
                  },
                  clearable: true,
                },
              },
              {
                title: "STATUS",
                field: "activoinactivo",
                validator: "required",
                editor: "list",
                editorParams: {
                  values: { Activo: "Activo", Inactivo: "Inactivo" },
                },
                headerFilter: true,
                headerFilterParams: {
                  values: {
                    Activo: "Activo",
                    Inactivo: "Inactivo",
                    "": "Todos",
                  },
                  clearable: true,
                },
              },
              {
                title: "Fecha Baja",
                field: "fechabaja",
                editor: "input",
              },
              {
                title: "Frecuencia",
                field: "frecuenciadepago",
                editor: "input",
              },
              {
                title: "Acceso",
                field: "fijamovil",
                editor: "list",
                editorParams: { values: { Fija: "Fija", Movil: "Movil" } },
                headerFilter: true,
                headerFilterParams: {
                  values: { Fija: "Fija", Movil: "Movil", "": "Todos" },
                  clearable: true,
                },
              },
            ],
          });

          // ------------------------------------------------------
          // Bloqueamos edición si no hay permisos o no hay contrato
          // ------------------------------------------------------
          newTable.on("cellEditing", function (cell) {
            if (cell._alreadyToasted) {
              cell.cancelEdit();
              return;
            }

            if (!canWrite) {
              M.toast({
                html: "No tienes permiso para editar.",
                classes: "red",
              });
              cell._alreadyToasted = true;
              cell.cancelEdit();
              return;
            }

            const idClienteSeleccionado = localStorage.getItem(
              "idClienteSeleccionado"
            );
            if (!idClienteSeleccionado) {
              cell.cancelEdit();
              M.toast({
                html: "Selecciona un contrato antes de guardar.",
                classes: "red",
                displayLength: 4000,
              });
              cell._alreadyToasted = true;
              return;
            }
          });

          // Cuando se cancele la edición, limpiamos la marca
          newTable.on("cellEditCancelled", function (cell) {
            // delete cell._alreadyToasted;
          });

          // ------------------------------------------------------
          // Guardar cambios al terminar de editar una celda
          // ------------------------------------------------------
          newTable.on("cellEdited", async function (cell) {
            if (cell._editInProgress) return;
            cell._editInProgress = true;

            const updatedRow = cell.getRow().getData();
            const updatedValue = cell.getValue();

            const idClienteSeleccionado = localStorage.getItem(
              "idClienteSeleccionado"
            );

            const payload = {
              empnum: updatedRow.empnum,
              nombre: updatedRow.nombre.toUpperCase(),
              paterno: updatedRow.paterno.toUpperCase(),
              materno: updatedRow.materno
                ? updatedRow.materno.toUpperCase()
                : " ",
              curp: updatedRow.curp ? updatedRow.curp.toUpperCase() : " ",
              sucursal: localStorage.getItem("nameContrato"),
              frecuenciaDePago: updatedRow.frecuenciadepago.toUpperCase(),
              fechaDeInicio: updatedRow.fechadeinicio,
              fechaBaja: updatedRow.fechabaja ? updatedRow.fechabaja : "",
              fechaReingreso: "",
              fijamovil: updatedRow.fijamovil ? updatedRow.fijamovil : " ",
              horarioDeEntrada24hh: updatedRow.horaentrada
                ? updatedRow.horaentrada
                : " ",
              horarioDeSalida60mm: updatedRow.horasalida
                ? updatedRow.horasalida
                : " ",
              toleranciaARetardoMmssNo: updatedRow.e_attribute1
                ? updatedRow.e_attribute1.toUpperCase()
                : " ",
              horasExtraSino: updatedRow.e_attribute2
                ? updatedRow.e_attribute2.toUpperCase()
                : " ",
              turnoDominicalSino: updatedRow.e_attribute3
                ? updatedRow.e_attribute3.toUpperCase()
                : " ",
              diasDeDescanso16: updatedRow.e_attribute4
                ? updatedRow.e_attribute4.toUpperCase()
                : " ",
              horasSemanales: updatedRow.e_attribute5
                ? updatedRow.e_attribute5.toUpperCase()
                : " ",
              activoInactivo: updatedRow.activoinactivo,
              fotoEnviada: updatedRow.fotoenviada
                ? updatedRow.fotoenviada
                : " ",
              email: updatedRow.email ? updatedRow.email : " ",
              clientid: idClienteSeleccionado,
            };

            console.log("Payload:", payload);

            try {
              const options = {
                method: "POST",
                headers: { "Content-Type": "application/json" },
                body: JSON.stringify(payload),
              };

              const response = await fetch(URL_POST, options);
              const responseData = await response.json();

              if (
                responseData.error_msg &&
                responseData.error_msg.includes("OK")
              ) {
                M.toast({
                  html: `Nuevo Valor: ${updatedValue}\nActualizado Correctamente!`,
                  classes: "green",
                });
              } else {
                M.toast({
                  html:
                    responseData.error_code === 1
                      ? responseData.error_msg
                      : `No se pudo actualizar`,
                  classes: "red",
                });
                cell.setValue(cell.getOldValue(), true);
              }
            } catch (error) {
              console.error("Error saving data:", error);
              M.toast({
                html: `Error: ${error.message}`,
                classes: "red",
              });
            } finally {
              cell._editInProgress = false;
            }
          });

          setTable(newTable);
        }
      }
    } catch (error) {
      console.error("Error loading table data:", error);
    } finally {
      setLoading(false);
    }
  }, [CLIENTID, URL_GET, URL_POST, canWrite]);

  // --------------------------------------------------------------------------
  // 2) INICIALIZAR LA TABLA CUANDO EL COMPONENTE ESTÉ LISTO
  // --------------------------------------------------------------------------
  useEffect(() => {
    if (tableRef.current) {
      loadTable();
    }
  }, [loadTable]);

  // --------------------------------------------------------------------------
  // 3) DESCARGAR EXCEL
  // --------------------------------------------------------------------------
  const downloadExcel = () => {
    if (!table) {
      console.error("Table instance not found");
      return;
    }
    const data = table.getData(); // Get the data directly

    // Convert data to a worksheet
    const ws = XLSX.utils.json_to_sheet(data);
    // Create a new workbook and append the worksheet
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Sheet1");
    // Save the workbook as an Excel file
    XLSX.writeFile(wb, "catalogo_Empleados.xlsx");
  };

  // --------------------------------------------------------------------------
  // 4) ACTUALIZAR CONTRATO (BULK UPDATE)
  // --------------------------------------------------------------------------
  const updateAllContract = async () => {
    if (!table) {
      console.error("No existe instancia de la tabla");
      return;
    }
    // Obtenemos todos los registros de la tabla
    const data = table.getData();

    // Leemos del localStorage la nueva sucursal y el clientid
    const newSucursal = localStorage.getItem("nameContrato") || "5-LICCO";
    const newClientId = localStorage.getItem("idClienteSeleccionado") || "1";

    // Recorremos la data y hacemos un POST al endpoint "actualizar" por cada empleado
    for (let row of data) {
      if (row.empnum === employeeIdToChange) {
        const payload = {
          empnum: row.empnum,
          nombre: row.nombre ? row.nombre.toUpperCase() : "",
          paterno: row.paterno ? row.paterno.toUpperCase() : "",
          materno: row.materno ? row.materno.toUpperCase() : " ",
          curp: row.curp ? row.curp.toUpperCase() : " ",
          sucursal: newSucursal, // NUEVA SUCURSAL
          frecuenciaDePago: row.frecuenciadepago
            ? row.frecuenciadepago.toUpperCase()
            : "QUINCENA",
          fechaDeInicio: row.fechadeinicio || "2025-01-21",
          fechaBaja: row.fechabaja || "",
          fechaReingreso: "",
          fijamovil: row.fijamovil || "Fija",
          horarioDeEntrada24hh: row.horaentrada || "12:00",
          horarioDeSalida60mm: row.horasalida || "20:00",
          toleranciaARetardoMmssNo: row.e_attribute1
            ? row.e_attribute1.toUpperCase()
            : "PPP",
          horasExtraSino: row.e_attribute2
            ? row.e_attribute2.toUpperCase()
            : "LLL",
          turnoDominicalSino: row.e_attribute3
            ? row.e_attribute3.toUpperCase()
            : "",
          diasDeDescanso16: row.e_attribute4
            ? row.e_attribute4.toUpperCase()
            : "2",
          horasSemanales: row.e_attribute5
            ? row.e_attribute5.toUpperCase()
            : "48",
          activoInactivo: row.activoinactivo || "Activo",
          fotoEnviada: row.fotoenviada || "Si",
          email: row.email || "correotest@gmail.com",
          clientid: newClientId, // NUEVO CLIENT ID
        };

        try {
          const response = await fetch(URL_POST, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify(payload),
          });
          const responseData = await response.json();

          if (
            responseData.error_code === "0" ||
            (responseData.error_msg && responseData.error_msg.includes("OK"))
          ) {
            console.log(`EmpID ${row.empnum}: contrato actualizado.`);
          } else {
            console.error(
              `EmpID ${row.empnum}: error en actualización.`,
              responseData
            );
            M.toast({
              html: `Error al actualizar a ${row.empnum}`,
              classes: "red",
            });
          }
        } catch (error) {
          console.error(`EmpID ${row.empnum}: error en fetch`, error);
          M.toast({
            html: `Error de red con empleado ${row.empnum}`,
            classes: "red",
          });
        }
      }
    }

    // Al final, notifica que terminó el proceso
    M.toast({
      html: "¡Contrato actualizado masivamente!",
      classes: "green",
      displayLength: 4000,
    });
  };

  // --------------------------------------------------------------------------
  // RENDER
  // --------------------------------------------------------------------------
  return (
    <div style={{ padding: "10px" }}>
      <div ref={tableRef} id="employee-table"></div>

      <div style={{ marginTop: "20px" }}>
        <StyledExcelButton onClick={downloadExcel}>
          Descargar Excel
        </StyledExcelButton>

        {/* Input para el EmpID */}
        <div className="input-field col s4" style={{ marginLeft: "20px" }}>
          <input
            type="text"
            value={employeeIdToChange}
            onChange={(e) => setEmployeeIdToChange(e.target.value)}
          />
          <label>Empleado a cambiar</label>
        </div>

        {/* Botón para disparar el cambio */}
        <div
          className="col s12"
          style={{ marginLeft: "20px", marginTop: "20px" }}
        >
          <button className="btn" onClick={updateAllContract}>
            Actualizar Contrato de este empleado
          </button>
        </div>
      </div>

      {loading && (
        <div className="progress" style={{ marginTop: "20px" }}>
          <div className="indeterminate"></div>
        </div>
      )}
    </div>
  );
};

export default EmployeeCatalogComponent;
