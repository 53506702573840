import React, { useState, useCallback } from "react";
import { StyledSubtitle, Avatar, colors } from "../components/Styles";
import Logo from "../assets/liccoLogo.png";
import Navbar from "../components/NavbarUsers";
import CountFTableWeek from "../components/dashboard/TablaFaltasWeek";
import CountTablaHoy from "../components/dashboard/countHoy";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";

const Dashboard = () => {
  window.localStorage.removeItem("idClienteSeleccionado");
  window.localStorage.removeItem("idContratoSeleccionado");
  window.localStorage.removeItem("nameContrato");
  const [selectedIds, setSelectedIds] = useState([]);

  // Memoriza la función handleIdsSelection
  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
    //console.log("Selected IDs:", ids); // Maneja los IDs seleccionados aquí
  }, []);

  return (
    <div>
      <div>
        <Navbar />
      </div>
      <Avatar $image={Logo} />

      <div className="row">
        <div className="col s3">
          <StyledSubtitle color={colors.theme}>
            Seleccionar Contratos
          </StyledSubtitle>
          <SelectIdsComponent onSelect={handleIdsSelection} isMulti={true} />
        </div>
        <div className="col s3" style={{ display: "none" }}>
          <StyledSubtitle color={colors.theme}>
            Faltas en los últimos 7 días
          </StyledSubtitle>
          <CountFTableWeek selectedIds={selectedIds} />
        </div>
        <div className="col s6">
          <StyledSubtitle color={colors.theme}>
            Movimientos el día de hoy
          </StyledSubtitle>
          <CountTablaHoy selectedIds={selectedIds} />
        </div>
      </div>
    </div>
  );
};

export default Dashboard;
