import CalendarioF from "../components/calendario/calendarioFinal";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";
import { useCallback, useState } from "react";

const CalendarioPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
    console.log("Selected IDs:", ids); // Maneja los IDs seleccionados aquí
  }, []);
  return (
    <div>
      <Navbar />
      <div className="row">
        <StyledSubtitle color={colors.theme} size={24}>
          Calendario Por Fechas
        </StyledSubtitle>
      </div>
      <div className="row">
        <SelectIdsComponent onSelect={handleIdsSelection} isMulti={false} />
      </div>
      <CalendarioF />
    </div>
  );
};

export default CalendarioPage;
