// src/components/apiCredentials.js

// ClientID: si lo sigues usando para otras cosas
export const CLIENTID = "9";

/**
 * Determina si el usuario puede editar/escribir
 * (ejemplo: solo si role_name != "USER").
 *
 * @returns {boolean} true si tiene permisos de edición, false si está bloqueado
 */
export function hasWritePermissions() {
  // Lee el role_name de localStorage
  const roleName = window.localStorage.getItem("role_name");
  // Retorna false si es "USER", true en caso contrario
  //return true;
  return roleName !== "USER";
}
