// src/pages/EmployeesPage.js

import React, { useState, useCallback, useEffect, useRef } from "react";
import M from "materialize-css/dist/js/materialize.min.js";
import Navbar from "../components/NavbarUsers";
import AltaEmpleado from "../components/empleados/AltaEmpleado";
import EmployeeCatalogComponent from "../components/empleados/Employees";
import { StyledSubtitle, colors } from "../components/Styles";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";

const EmployeesPage = () => {
  // Selección para el primer (o principal) Select
  const [selectedIdsMain, setSelectedIdsMain] = useState([]);
  // Selección para el segundo Select
  const [selectedIdsChange, setSelectedIdsChange] = useState([]);
  // Controla si se muestra o no el segundo Select
  const [showChangeSelect, setShowChangeSelect] = useState(false);

  // Input donde el usuario ingresa el EmpID a cambiar
  const [employeeIdToChange, setEmployeeIdToChange] = useState("");

  // Ref para llamar métodos dentro de EmployeeCatalogComponent
  const catalogRef = useRef(null);

  useEffect(() => {
    // Limpiamos datos antiguos
    localStorage.removeItem("idContratoSeleccionado");
    localStorage.removeItem("idClienteSeleccionado");
    localStorage.removeItem("idChange");

    M.AutoInit();
  }, []);

  // Maneja la selección del primer Select
  const handleIdsSelectionMain = useCallback((ids) => {
    setSelectedIdsMain(ids);
    console.log("Selected IDs (MAIN):", ids);
  }, []);

  // Maneja la selección del segundo Select
  const handleIdsSelectionChange = useCallback((ids) => {
    setSelectedIdsChange(ids);
    console.log("Selected IDs (CHANGE):", ids);

    // Guardar en localStorage la selección, con la clave "idChange"
    if (Array.isArray(ids)) {
      localStorage.setItem("idChange", ids[0] || "");
    } else {
      localStorage.setItem("idChange", ids);
    }
  }, []);

  // Maneja el checkbox
  const handleCheckboxChange = (e) => {
    const checked = e.target.checked;
    setShowChangeSelect(checked);

    if (!checked) {
      localStorage.removeItem("idChange");
      setSelectedIdsChange([]);
    }
  };

  // FUNCIÓN: Cambiar contrato para UN solo empleado
  const handleChangeContractForOneEmployee = async () => {
    // 1) Validar que haya un contrato secundario seleccionado
    const newSucursal = localStorage.getItem("nameContrato");
    const newClientId = localStorage.getItem("idClienteSeleccionado");
    if (!newSucursal || !newClientId) {
      M.toast({
        html: "Selecciona un contrato (Secundario) antes de actualizar.",
        classes: "red",
      });
      return;
    }

    // 2) Validar que el user haya ingresado un EmpID
    if (!employeeIdToChange) {
      M.toast({
        html: "Escribe un EmpID a cambiar.",
        classes: "red",
      });
      return;
    }

    // 3) Llamar un método de EmployeeCatalogComponent que busque el row con ese EmpID
    //    y lance el POST de actualización con la nueva sucursal. (Ver EmployeeCatalogComponent más abajo)
    if (catalogRef.current && catalogRef.current.updateOneEmployee) {
      await catalogRef.current.updateOneEmployee(employeeIdToChange);
    }
  };

  return (
    <div>
      <Navbar />

      {/* Contrato Principal */}
      <div className="row">
        <StyledSubtitle color={colors.theme}>
          Seleccionar Contrato (Principal)
        </StyledSubtitle>
        <SelectIdsComponent onSelect={handleIdsSelectionMain} />
      </div>

      {/* Checkbox para el Contrato Secundario */}
      <div className="row" style={{ padding: "0 20px" }}>
        <label>
          <input
            type="checkbox"
            checked={showChangeSelect}
            onChange={handleCheckboxChange}
          />
          <span>¿Cambiar de contrato?</span>
        </label>
      </div>

      {showChangeSelect && (
        <div className="row">
          <StyledSubtitle color={colors.theme}>
            Seleccionar Contrato (Secundario)
          </StyledSubtitle>
          <SelectIdsComponent
            context="secondary"
            onSelect={handleIdsSelectionChange}
          />
        </div>
      )}

      <StyledSubtitle color={colors.theme} size={24}>
        Catálogo de Empleados
      </StyledSubtitle>
      <EmployeeCatalogComponent
        ref={catalogRef}
        selectedIds={selectedIdsMain}
      />

      <StyledSubtitle color={colors.theme} size={24}>
        Alta Nuevo Empleado
      </StyledSubtitle>
      <AltaEmpleado selectedIds={selectedIdsMain} />
    </div>
  );
};

export default EmployeesPage;
