import PrenominaR from "../components/formatos/FormatoPrenomina";
import Navbar from "../components/NavbarUsers";
import { StyledSubtitle, colors } from "../components/Styles";
import SelectIdsComponent from "../components/dashboard/SelectIdsComponent";
import { useCallback, useState } from "react";

const FormatPage = () => {
  const [selectedIds, setSelectedIds] = useState([]);
  // Memoriza la función handleIdsSelection
  const handleIdsSelection = useCallback((ids) => {
    setSelectedIds(ids);
    console.log("Selected IDs:", ids); // Maneja los IDs seleccionados aquí
  }, []);
  return (
    <div>
      <Navbar />
      <div className="container">
        <StyledSubtitle color={colors.theme} size={24}>
          Formato de Prenómina
        </StyledSubtitle>
        <div className="row">
          <SelectIdsComponent onSelect={handleIdsSelection} isMulti={false} />
        </div>
        <PrenominaR />
      </div>
    </div>
  );
};

export default FormatPage;
