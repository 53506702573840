import React, { useState, useEffect, useRef } from "react";
import "tabulator-tables/dist/css/tabulator.min.css";
import "flatpickr/dist/flatpickr.min.css";
import Flatpickr from "flatpickr";
import { Spanish } from "flatpickr/dist/l10n/es"; // Import Spanish locale
import { TabulatorFull as Tabulator } from "tabulator-tables";
import * as XLSX from "xlsx";
import { StyledFormButton, StyledExcelButton } from "../Styles";
import "flatpickr/dist/flatpickr.min.css"; // Default Flatpickr styles
import "../custom-flatpickr.css";

const CalendarioF = () => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  const [table, setTable] = useState(null);
  const [loading, setLoading] = useState(false);

  const startDateRef = useRef(null);
  const endDateRef = useRef(null);
  const CLIENTID = window.localStorage.getItem("idClienteSeleccionado");

  useEffect(() => {
    const today = new Date();
    const fifteenDaysAgo = new Date();
    fifteenDaysAgo.setDate(today.getDate() - 7);

    const formattedToday = today.toISOString().split("T")[0];
    const formattedFifteenDaysAgo = fifteenDaysAgo.toISOString().split("T")[0];

    setStartDate(formattedFifteenDaysAgo);
    setEndDate(formattedToday);
  }, []);

  // Initialize Flatpickr with Spanish locale and Materialize CSS styles
  useEffect(() => {
    Flatpickr(startDateRef.current, {
      dateFormat: "Y-m-d",
      locale: Spanish, // Use Spanish locale
      defaultDate: startDate,
      onChange: ([date]) => setStartDate(date.toISOString().split("T")[0]),
    });
    Flatpickr(endDateRef.current, {
      dateFormat: "Y-m-d",
      locale: Spanish, // Use Spanish locale
      defaultDate: endDate,
      onChange: ([date]) => setEndDate(date.toISOString().split("T")[0]),
    });
  }, [startDate, endDate]);

  // Redraw the table whenever the table instance changes
  useEffect(() => {
    if (table) {
      table.redraw(true);
    }
  }, [table]);

  // Fetch data and populate table
  const handleFetchData = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    if (table) {
      table.setData(transformedData);
      table.setColumns(columns);
    } else {
      const newTable = new Tabulator("#data-table", {
        data: transformedData,
        layout: "fitDataStretch",
        columns: columns,
        groupBy: "Site",
        groupHeader: function (value, count) {
          // Only show the count, without the label
          return `${value}: ${count}`;
        },
        pagination: "remote",
        paginationSize: 22,
      });
      setTable(newTable);
    }
    setLoading(false);
  };

  // Download data as Excel
  const handleDownload = async () => {
    setLoading(true);
    const { transformedData, columns } = await fetchData(startDate, endDate);
    const downloadableData = transformedData.map((row) => {
      const newRow = {};
      columns.forEach((col) => {
        newRow[col.title] = row[col.field];
      });
      return newRow;
    });

    const worksheet = XLSX.utils.json_to_sheet(downloadableData);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const dataUri = XLSX.write(workbook, { bookType: "xlsx", type: "base64" });
    const link = document.createElement("a");
    const downloadName = `${
      transformedData[0]?.contract_name || "data"
    }_${startDate}-${endDate}`;
    link.href = `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,${dataUri}`;
    link.download = `${downloadName}.xlsx`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    setLoading(false);
  };

  // Fetch data from the API and transform it for the table
  const fetchData = async (startDate, endDate) => {
    const baseUrl =
      "https://894bdij9ij.execute-api.us-east-1.amazonaws.com/licco/asistencias/prenom/";
    const url = `${baseUrl}${startDate}/${endDate}/${CLIENTID}`;

    try {
      const response = await fetch(url);
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      return transformData(data);
    } catch (error) {
      console.error("Error fetching data:", error);
      return { transformedData: [], columns: [] };
    }
  };

  // Transform data and add columns for counts of specific "hora" and "retardo"
  const transformData = (data) => {
    const pivotData = {};
    const dates = new Set();
    let maxFI = 0;

    // Initialize counters for specific 'hora' values and retardo

    data.forEach((item) => {
      if (!pivotData[item.nombre]) {
        pivotData[item.nombre] = {
          nombre: item.nombre,
          contract_name: item.contract_name,
          Site: item.e_attribute4,
          "ID Nominas": item.employee_number,
          PUESTO: item.e_attribute3,
          fechadeinicio: item.fechadeinicio,
          fechabaja: item.fechabaja,
          horario: item.horas,
          retardoCount: 0,
          FI: 0,
          V: 0,
          INC: 0,
          CAP: 0,
          B: 0,
          HO: 0,
          PH: 0,
          F: 0,
          "PS/G": 0,
          "PC/G": 0,
          FJ: 0,
          C: 0,
        };
      }

      // Count specific 'hora' values

      if (item.hora === "FI" || item.hora === "F") pivotData[item.nombre].FI++;
      if (item.hora === 'Sab' || item.hora === 'Dom' || item.hora === 'D') pivotData[item.nombre].D++;
      if (item.hora === 'V') pivotData[item.nombre].V++;
      if (item.hora === "V") pivotData[item.nombre].V++;
      if (item.hora === "INC") pivotData[item.nombre].INC++;
      if (item.hora === "CAP") pivotData[item.nombre].CAP++;
      if (item.hora === "B") pivotData[item.nombre].B++;
      if (item.hora === "HO") pivotData[item.nombre].HO++;
      if (item.hora === "PH") pivotData[item.nombre].PH++;
      if (item.hora === "PS/G") pivotData[item.nombre]["PS/G"]++;
      if (item.hora === "PC/G") pivotData[item.nombre]["PC/G"]++;
      if (item.hora === "FJ") pivotData[item.nombre].FJ++;
      if (item.hora === "C") pivotData[item.nombre].C++;
      if (item.retardo === "Y") pivotData[item.nombre].retardoCount++;
      pivotData[item.nombre][item.fecha] = item.hora;
      pivotData[item.nombre][`${item.fecha}_retardo`] = item.retardo;
      dates.add(item.fecha);
    });

    Object.values(pivotData).forEach((item) => {
      if (item.FI > maxFI) {
        maxFI = item.FI;
      }
    });

    const transformedData = Object.values(pivotData);

    const columns = [
      { title: "#", field: "rownum", width: 22, frozen: true, editable: false },

      {
        title: "EmpId",
        field: "ID Nominas",
        frozen: true,
        width: 75,
        headerFilter: "input",
        headerFilterPlaceholder: "Buscar...",
      },

      {
        title: "Nombre",
        field: "nombre",
        headerFilter: "input",
        frozen: true,
        headerFilterFunc: (headerValue, rowValue) =>
          rowValue.toLowerCase().includes(headerValue.toLowerCase()),
      },
      { title: "PUESTO", field: "PUESTO", width: 99 },
      { title: "Contrato", field: "contract_name", width: 85 },
      { title: "Ingreso", field: "fechadeinicio", width: 82 },
      { title: "Baja", field: "fechabaja", width: 77 },
      { title: "Horario", field: "horario", width: 83 },
      {
        title: "FI",
        field: "FI",
        width: 57,
        hozAlign: "center",
        formatter: function (cell) {
          const value = cell.getValue();
          const cellElement = cell.getElement();

          // Determine the background color based on the value
          let backgroundColor;

          if (value === 0) {
            backgroundColor = "white"; // For value 0
          } else if (value === 1 || value === 2) {
            backgroundColor = "pink"; // For values 1 and 2
          } else {
            backgroundColor = "red"; // For values 3 or more
          }

          // Set the background color based on the value
          cellElement.style.backgroundColor = backgroundColor;
          cellElement.style.color = value > 0 ? "white" : "black"; // Optional: Adjust text color for better contrast

          return value;
        },
      },
      {
        title: "R",
        field: "retardoCount",
        width: 52,
        hozAlign: "center",
        formatter: function (cell) {
          const value = cell.getValue();
          const cellElement = cell.getElement();

          // Determine the background color based on the value
          let backgroundColor;

          if (value === 0) {
            backgroundColor = "white"; // For value 0
          } else if (value >= 1 && value <= 4) {
            backgroundColor = "pink"; // For values 1 and 2
          } else {
            backgroundColor = "red"; // For values 3 or more
          }

          // Set the background color based on the value
          cellElement.style.backgroundColor = backgroundColor;
          cellElement.style.color = value > 0 ? "white" : "black"; // Optional: Adjust text color for better contrast

          return value;
        },
      },
      { title: "INC", field: "INC", width: 67, hozAlign: "center" },
      { title: "V", field: "V", width: 53, hozAlign: "center" },
      { title: "CAP", field: "CAP", width: 67, hozAlign: "center" },
      { title: "B", field: "B", width: 53, hozAlign: "center" },
      { title: "HO", field: "HO", width: 62, hozAlign: "center" },
      { title: "PH", field: "PH", width: 62, hozAlign: "center" },
      { title: "FJ", field: "FJ", width: 61, hozAlign: "center" },

      { title: "PS/G", field: "PS/G", width: 70, hozAlign: "center" },
      { title: "PC/G", field: "PC/G", width: 70, hozAlign: "center" },

      { title: "C", field: "C", width: 56, hozAlign: "center" },
    ];

    dates.forEach((date) => {
      columns.push({
        title: date,
        field: date,
        headerVertical: true,
        hozAlign: "center",
        formatter: function (cell) {
          const value = cell.getValue(); // Get the value of the cell
          const rowData = cell.getRow().getData(); // Get the row data
          if(value === 'Sab' || value === 'Dom'){
            return 'D';
          }

          // Check if the retardo for this date and row is "Y"
          const isRetardo = rowData[`${date}_retardo`] === "Y";

          // Debugging: Log the row data and condition
          //console.log(`Date: ${date}, Retardo: ${rowData[`${date}_retardo`]}`);

          // Set the text color based on the retardo value
          cell.getElement().style.color = isRetardo ? "red" : "black";

          return value; // Return the value to be displayed in the cell
        },
      });
    });
    return { transformedData, columns };
  };

  return (
    <div>
      <div className="row">
        <div className="col s3">
          <label>Fecha de inicio</label>
          <input ref={startDateRef} type="text" />
        </div>
        <div className="col s3">
          <label>Fecha de fin</label>
          <input ref={endDateRef} type="text" />
        </div>
      </div>

      <div className="row">
        <div className="col s6">
          <StyledFormButton onClick={handleFetchData}>Buscar</StyledFormButton>
        </div>
        <div className="col s3 right">
          <StyledExcelButton onClick={handleDownload}>
            Descargar Excel
          </StyledExcelButton>
        </div>
      </div>
      {loading && (
        <div className="progress">
          <div className="indeterminate"></div>
        </div>
      )}

      <div id="data-table" />
    </div>
  );
};

export default CalendarioF;
